html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div {
  font-family: Montserrat;
}

@font-face {
  font-family: "AKONY";
  src: local("AKONY"), url(./fonts/akony.otf) format("opentype");
}

@font-face {
  font-family: "kanit";
  src: local("kanit"), url(./fonts/kanit.ttf) format("truetype");
}

@font-face {
  font-family: "Acherus Grotesque Regular";
  src: local("Acherus Grotesque Regular"),
    url(./fonts/horizon.otf) format("opentype");
}

@font-face {
  font-family: "Medium";
  src: local("Medium"), url(./fonts/medium.otf) format("opentype");
}
@font-face {
  font-family: "VERSATILE";
  src: local("VERSATILE"), url(./fonts/versatile.otf) format("opentype");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url(./fonts/montserrat.ttf) format("truetype");
}

.main {
  width: 100%;
  height: 100%;
  font-family: Manado;
  background-color: white;
}

p {
  font-size: 18px;
}

video {
  width: 100%;
  height: 900px;
  object-fit: cover;
  opacity: 0;
  overflow: hidden;
  /* backdrop-filter: blur(4px); */
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}

.nav-menu-item {
  font-family: Montserrat !important;
  font-weight: 600 !important;
  color: #000080 !important;
}

.nav-item {
  margin: 5px;
  padding: 0px 10px 0px 10px;
  border-radius: 20px;
  font-size: 25px;
  cursor: pointer;
  font-family: Montserrat;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  text-shadow: 5px 5px 10px black;

}

.sub-nav {
  margin: 5px 15px 5px 15px;
  padding: 0px 10px 0px 10px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-size: 22;
  text-shadow: 5px 5px 10px black;
}

.hero {
  padding: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  mix-blend-mode: difference;
}

.abouthero  {
  object-fit: fill;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  border-bottom-left-radius: 100% 50%;
  border-bottom-right-radius: 100% 50%;
  text-shadow: 8px 8px 18px black;
  background-size: fill;
}

.newshero {
  object-fit: fill;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  padding: 50px;
  text-shadow: 8px 8px 18px black;
}

.news{
  padding:50px;
}

.aboutabout {
  padding: 0px 50px 50px 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.aboutabout2 {
  padding: 250px 50px 50px 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.aboutabout3 {
  padding: 250px 0px 0px 0px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutabout4 {
  padding: 50px;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.growth {
  display: flex;
  height: 50vh;
  justify-content: center;
  align-items: center;
  background-color: white;
}



.icard {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.sub-nav-ul {
  height: "5px";
  width: "50px";
  background-color: "white";
}

.nav-icon {
  color: white;
}

.button-carousel {
  cursor: pointer;
  padding: 8px 16px;
  margin: 10px 5px;
}

.slider {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.controls {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  min-height: 70px;
}

.slides-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
}

.slide {
  position: absolute;
  font-size: 90px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(100% / 3);
  width: 100%;
}

.slides-inner {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.topic {
  color: white;
  font-size: 30px;
  font-family: Montserrat;
  font-weight: 600;
  position: sticky;
}

.description {
  margin-bottom: 35px;
  font-size: 3.34vh;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: Montserrat;
  padding: 35px;
  border-radius: 25px;
  color: white;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 1), 0 6px 20px 0 rgba(0, 0, 0, 1);
  font-size: 20px;
  text-align: left;
}

.descimg {
  margin-bottom: 25px;
}

.herotitle{
  text-shadow: "8px 8px 18px black",
}